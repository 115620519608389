<template>
  <ion-icon slot="start" v-if="show(valueObject[attribute.attrName])" :icon="compareLock(attribute, valueObject[attribute.attrName])?lockClosed:lockOpenOutline" @click.stop="lockAttribute(attribute, valueObject[attribute.attrName], $event)"/>
</template>
<script>
import { IonIcon } from '@ionic/vue'
import { 
  lockOpenOutline, 
  lockClosed,
} from 'ionicons/icons'
export default {
  components: {
    IonIcon
  },
  props: {
    attribute: Object,
    valueObject: Object,
  },
  data () {
    return {
      lockOpenOutline,
      lockClosed,
    }
  },
  methods: {
    lockAttribute(attribute, value, event) {
      event.stopPropagation()
      if (attribute.lock==value) {
        attribute.lock = null
        console.log(attribute.lock)
      }
      else {
        attribute["lock"] = value
        console.log(attribute.lock)
      }
    },
    show(value) {
      if (Array.isArray(value)&&value.length>0)
       return true
      else if (!Array.isArray(value)&&value)
        return true
      else
       return false
    },
    compareLock(attribute, value) {
      if (Array.isArray(value)) {
        return JSON.stringify(attribute.lock)==JSON.stringify(value)
      }
      else
        return attribute.lock==value
    },
  }
}
</script>
<style scoped>
ion-icon {
  font-size: 1.4rem;
  padding-right: .4rem;
}
</style>