<template>
  <ion-page>
    <ion-content>
    <ion-tabs>
    <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button :disabled="!storage.user.profile" tab="home" @click="$router.replace('/home')">
          <ion-icon :icon="homeOutline"></ion-icon>
          <ion-label>Start</ion-label>
        </ion-tab-button>
        <ion-tab-button :disabled="!storage.user.profile"  tab="eingabe" href="/tabs/eingabe">
          <ion-icon :icon="addOutline"></ion-icon>
          <ion-label>Eingabe</ion-label>
        </ion-tab-button>
        <ion-tab-button :disabled="!storage.user.profile"  tab="samples" href="/tabs/funde">
          <ion-icon :icon="listOutline"></ion-icon>
          <ion-label>Funde</ion-label>
          <ion-badge :style="'--background:'+colorStatus['In Bearbeitung']">{{count}}</ion-badge>
        </ion-tab-button>
        <ion-tab-button tab="sonstiges" href="/tabs/sonstiges">
          <ion-icon :icon="ellipsisVertical"></ion-icon>
          <ion-label>Sonstiges</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script>
import { CONFIG } from '../helpers/config.js'
import {
  IonContent,
  IonIcon, 
  IonLabel, 
  IonPage,
  IonTabBar, 
  IonTabButton, 
  IonTabs,
  IonRouterOutlet,
  IonBadge
} from '@ionic/vue';
import { 
      homeOutline,
      addOutline,
      listOutline,
      ellipsisVertical,
} from 'ionicons/icons';

export default {
  inject: ['storage'],
  data () {
    return {
      homeOutline,
      addOutline,
      listOutline,
      ellipsisVertical,
      colorStatus: CONFIG.colorStatus
    }
  },
  created () {
    this.storage.status = "In Bearbeitung"
  },
  computed: {
    count: function () {
      let countSamples = {
        "In Bearbeitung": 0,
        "Meine Funde": 0,
        "Andere Funde": 0,
      }
      for (const sample of Object.values(this.storage.samples)) {
        if (sample.type!="subplot")
          countSamples[sample.status]++
      }
      return countSamples["In Bearbeitung"]
    }
  },
  components: {
    IonContent,
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonRouterOutlet,
    IonBadge
  },
}
</script>
<style scoped>
ion-icon {
  font-size: 1.6rem; 
}
ion-badge {
  font-size: .8rem; 
}
</style>