<template>
  <ion-page>
    <span v-if="attrKey[attribute]">
      <ion-header>
        <ion-toolbar color="secondary" style="--padding-end:0">
          <ion-buttons slot="start">
            <ion-back-button text="" defaultHref="/"></ion-back-button>
          </ion-buttons>
          <ion-title>{{attrKey[attribute].text}}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-fab v-if="detType=='checkbox'" vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="$router.push({name:'singleocc',params:{sample:id,occ:occ}})">
          <ion-icon :icon="checkmarkDoneOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </span>
    <ion-fab vertical="bottom" horizontal="end" slot="fixed">
      <ion-fab-button @click="clearValue">
        <ion-icon :icon="trashBinOutline"></ion-icon>
      </ion-fab-button>
    </ion-fab>
    <ion-content>
      <span v-if="attrKey[attribute]">
        <ion-item v-for="(item, k) in attrKey[attribute].values" :key="k" @click="clickEntry(item, $event)">
          <span v-if="item.parent==parent||attrKey[attribute].controlType!='hierarchical_select'" class="item-radio">
            <input :type="detType" v-model="occOrSample" :id="item.value" :value="item.value">
            <i class="radio-icon material-icons">check</i>
            <label :for="item.value" style="font-size:1.1rem">{{ item.text }}</label>
          </span>
        </ion-item>
      </span>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonFab,
  IonFabButton,
  IonIcon,
  IonBackButton,
  IonButtons
} from '@ionic/vue'
import { 
  trashBinOutline,
} from 'ionicons/icons'
import { useRoute } from 'vue-router'
export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonItem,
    IonFab,
    IonFabButton,
    IonIcon,
    IonBackButton,
    IonButtons
  },
  inject: ['storage'],
  data () {
    return {
      route: useRoute(),
      id: null,
      occ: null,
      attribute: "dummy",
      attrKey: {dummy: null},
      sample: null,
      occurrence: null,
      parent: null,
      index: null,
      trashBinOutline
    }
  },
  ionViewWillEnter() {
    this.id = this.route.params.id
    this.occ = this.route.params.occ,
    this.attribute = this.route.params.attribute
    this.parent = this.route.params.parent=='null'?null:this.route.params.parent
    this.index = this.route.params.index
    this.sample = this.storage.samples[this.id],
    this.occurrence = this.storage.samples[this.id].occurrences[this.occ]
    console.log("radiolist: "+this.parent+"/"+this.index)
  },
  computed: {
    detType: function () {
      if (this.attrKey[this.attribute].controlType=='checkbox_group'||this.attrKey[this.attribute].controlType=='listbox')
        return "checkbox"
      else
        return "radio"
    },
    occOrSample: {
      get() {
        if(this.attrKey[this.attribute].attributeType=='O') {
          if (this.occurrence[this.attribute.slice(1)]&&this.occurrence[this.attribute.slice(1)][this.index]) {
            return this.occurrence[this.attribute.slice(1)][this.index].value
          }
          else {
            return this.occurrence[this.attribute.slice(1)]
          }
        }
        else {
          if (this.sample[this.attribute.slice(1)]&&this.sample[this.attribute.slice(1)][this.index]) {
            return this.sample[this.attribute.slice(1)][this.index].value
          }
          else {
            return this.sample[this.attribute.slice(1)]
          }
        }
      },
      set(newValue) {
        console.log(newValue)

        const removeOrAdd = obj => {
          console.log(this.detType)
          if (this.detType=="checkbox") {
            if (!Array.isArray(newValue)) {
              const index = obj[this.attribute.slice(1)].indexOf(newValue)
              if (index > -1) {
                obj[this.attribute.slice(1)].splice(index, 1)
              }
              else
                obj[this.attribute.slice(1)].push(newValue)
            }
            else {
              obj[this.attribute.slice(1)] = newValue
            }
          }
          if (this.detType=="radio"&&!(this.index>=0)) {
            obj[this.attribute.slice(1)] = newValue
          }
          if (this.detType=="radio") {
            this.$router.push({name: "singleocc", params: {sample: this.id, occ: this.occ}})
          }
        }

        if(this.attrKey[this.attribute].attributeType=='O') {
          removeOrAdd(this.occurrence)
        }
        else {
          removeOrAdd(this.sample)
        }
      }
    }
  },
  created() {
    for(const group of Object.values(this.storage.attributes)) {
      for(const attribute of Object.values(group.attributes)) {
        this.attrKey[attribute.attributeType+attribute.attrName] = attribute
      }
    }
  },
  methods: {
    clickEntry(item, event) {
      if (this.attrKey[this.attribute].attributeType=='O') {
        if (this.index>=0) {
          this.occurrence[this.attribute.slice(1)].splice(this.index)
          this.occurrence[this.attribute.slice(1)].push(item)
        }
      }
      else {
        if (this.index>=0) {
          this.sample[this.attribute.slice(1)].splice(this.index)
          this.sample[this.attribute.slice(1)].push(item)
        }
      }
    },
    clearValue() {
      if (this.attrKey[this.attribute].attributeType=='O') {
        if (Array.isArray(this.occurrence[this.attribute.slice(1)])) {
          this.occurrence[this.attribute.slice(1)] = []
        }
        else {
          this.occurrence[this.attribute.slice(1)] = null
        }
      }
      else {
        if (Array.isArray(this.sample[this.attribute.slice(1)])) {
          this.sample[this.attribute.slice(1)] = []
        }
        else {
          this.sample[this.attribute.slice(1)] = null
        }
      }
    },
  },
}
</script>
<style scoped>
.item-radio {
    position: relative;
    display: block;
    font-size: 1rem;
    padding-bottom: .3rem;
    width: 90%;
}
.item-radio input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
}
.item-radio .radio-icon {
    position: absolute;
    top: 0;
    right: -10%;
    font-size: 1rem;
    font-weight: 900;
    visibility: hidden;
    color: var(--ion-color-primary);
}
.item-radio input:checked + .radio-icon {
    visibility: visible;
}
</style>
