<template>
  <input type="file" style="display:none" :id="'fileinput'" 
    name="file" @change="addGeoDesktop($event)"/>
  <ion-fab vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button @click="loadNear">
      <ion-icon :icon="reloadOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab vertical="bottom" horizontal="start" slot="fixed">
    <ion-fab-button @click="loadGeo('fileinput')">
      <ion-icon :icon="folderOutline"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <LeafletMap
    :center="center?center:storage.config.mapView.center" :pointClick="false"
    :zoom="storage.config.mapView.zoom" :offline="true"
    :controls="true" :mapid="'nearby'" @componentready="setComponent">
  </LeafletMap>
</template>

<script>
import utilsMixin from '../mixins/utils.js'
import storeMixin from '../mixins/store.js'
import { CONFIG } from '../helpers/config.js'
import LeafletMap from './LeafletMap.vue'
import L from 'leaflet'
import axios from 'axios'
import proj4 from 'proj4'
import device from '../helpers/device.js'
import localForage from 'localforage'
import {
  alertController,
  IonContent,
  IonPage,
  IonToolbar,
  IonIcon,
  isPlatform,
  IonFab,
  IonFabButton,
} from '@ionic/vue'
import { 
  folderOutline, 
  reloadOutline,
} from 'ionicons/icons'

export default {
  components: {
    IonContent,
    IonPage,
    IonToolbar,
    IonIcon,
    IonFab,
    IonFabButton,
    LeafletMap,
  },
  inject: ['storage'],
  mixins: [utilsMixin, storeMixin],
  data () {
    return {
      mapZoom: 0,
      samplesGroup: null,
      map: null,
      mapComponent: null,
      fileList: {},
      filename: null,
      selected: "umgebung",
      segment: null,
      folderOutline,
      reloadOutline,
    }
  },
  ionViewWillEnter() {
    if (this.mapComponent) {
      if (this.mapComponent.finished)
        this.setComponent(this.mapComponent)
    }
  },
  ionViewDidEnter() {
    console.log("ionViewDidEnter "+this.selected)
    this.selected = "umgebung"
    this.map.invalidateSize()
  },
  props: {
    center: {},
    zoom: {
      type: Number
    },
    id: null
  },
  methods: {
    setSegment(segment) {
      console.log(segment.checked+"segmentsFundeReady ")
      this.segment = segment
    },
    async presentAlertFile() {
      let buttons = []
      if (Object.keys(this.fileList).length>0) {
        buttons = 
          [{
            text: 'Entfernen',
            handler: () => {
              this.deleteGeoJSON()
            }
          },
          {
            text: 'Anzeigen',
            handler: () => {
              if (this.filename) {
                this.addGeoCordova(this.filename)
                this.filename = null
              }
            }
          }]
      }
      else {
        buttons = 
          [{
            text: 'OK',
          }]
      }
      const alert = await alertController
        .create({
          header: Object.keys(this.fileList).length==0?'Keine geojson-Dateien kleiner 3MB im App-Verzeichnis gefunden':'Bitte geojson-Datei auswählen:',
          buttons: buttons,
          inputs: this.createInputs()
        })
      return alert.present()
    },
    createInputs() {
      const inputs = []
      for (const filename of Object.keys(this.fileList)) {
        inputs.push({
            type: 'radio',
            label: filename,
            value: filename,
            checked: this.storage.geoJSON.name==filename,
            handler: (input) =>  {
              this.filename = input.value
            }
        })
      }
      return inputs
    },
    configMapView() {
      this.storage.config = {
        ...this.storage.config,
        ...{mapView: {center: this.map.getCenter(), zoom: this.map.getZoom()}}
      }
    },
    mapSamples() {
      let circle
      if (this.samplesGroup) {
        this.map.removeLayer(this.samplesGroup)
      }
      this.samplesGroup = L.featureGroup().addTo(this.map)
      this.samplesGroup.on("click", (event) => {
        this.configMapView()
        if (this.storage.samples[event.layer.ID].polyline.length>0)
          this.$router.push({name: 'sample', params: {id: event.layer.ID}})
        else
          this.$router.push({name: 'singleocc', params: {sample: event.layer.ID, occ: this.singleOcc(event.layer.ID).id}})
      })
      for (const sample of Object.values(this.storage.samples)) {
        if (sample.xCentroid) {
//        L.rectangle(L.circle([0,0], 500).getBounds()).addTo(map)
        circle = L.circle([sample.yCentroid, sample.xCentroid], {
          radius: sample.blur?sample.blur/2:1,
          color: CONFIG.colorStatus[sample.status],
          fillOpacity: 0,
          fillColor : 'white'})
          .addTo(this.samplesGroup)
        circle.ID = sample.id
        }
      }
    },
    setComponent(component) {
      console.log("setComponent")
      if (!component.map)
        component.initMap(true)
      this.mapComponent = component
      this.map = component.map
      if (this.storage.samples != {})
        this.mapSamples()
      this.mapComponent.finished = true
    },
    loadNear() {
      if (!navigator.onLine) {
        CONFIG.headerAlert("Keine Internetverbindung", "Bitte später noch einmal versuchen.", [{text:'OK', role: 'ok'}])
      }
      else {
        const loading = this.presentLoading("Funde in meiner Umgebung werden geladen")
        let latLng = this.map.getCenter()
        let lng = latLng.lng.toFixed(7)
        let lat = latLng.lat.toFixed(8)
        let southWest = this.map.getBounds().getSouthWest()
        let west = proj4('EPSG:4326','EPSG:3857',[southWest.lng, southWest.lat])[0]
        let south = proj4('EPSG:4326','EPSG:3857',[southWest.lng, southWest.lat])[1]
        let northEast = this.map.getBounds().getNorthEast()
        let east = proj4('EPSG:4326','EPSG:3857',[northEast.lng, northEast.lat])[0]
        let north = proj4('EPSG:4326','EPSG:3857',[northEast.lng, northEast.lat])[1]
        let wkt = "POLYGON%28%28"
        wkt += west+"+"+south+"%2C"
        wkt += west+"+"+north+"%2C"
        wkt += east+"+"+north+"%2C"
        wkt += east+"+"+south+"%2C"
        wkt += west+"+"+south+"%29%29"
        axios.get(CONFIG.api+'/reports/mobile/occurrences/ffips-nearest_neighbour_list.xml?occattrs='+this.storage.occAttrs+'&smpattrs='+this.storage.smpAttrs+'&pointX='+lng+'&pointY='+lat+'&survey_id='+localStorage.getItem('surveyIndicia')).then(async response => {
          let status = "Andere Funde"
          this.deleteSamples(status)
          this.initSamples(status, response.data.data)
          this.mapSamples()
          loading.then(load => {load.dismiss()})
        })
      }
    },
    loadGeo(kind) {
      let directory
      let fail = (error) => {
        console.log("Failed to list directory contents: " + error.code);
      }
      if (window.cordova) {
        if (isPlatform("ios")) {
          directory = window.cordova.file.documentsDirectory
        } else if (device.isAndroid()) {
          directory = window.cordova.file.externalDataDirectory
        }
        window.resolveLocalFileSystemURL(directory, (dirEntry) => {
          dirEntry.createReader().readEntries(this.success, fail)
        })
      }
      else {
        document.getElementById(kind).click()
      }
    },
    async success(entries) {
      function fileInfo(entry) {
        const promise = new Promise(
          function(resolve) {
            entry.file(file => {
              resolve(file)
            })
          }
        )
        return promise
      }
      for (const entry of entries) {
        if (entry.isFile) {
          let file = await fileInfo(entry)
          if (file.name.split('.').pop().toLowerCase()=='geojson'&&file.size<3000000) {
            this.fileList[file.name.slice(0,-8)] = file
          }
        }
      }
      this.presentAlertFile()
    },
    async readGeo(file) {
      this.storage.geoJSON.name = file.name.slice(0,-8)
      const loading = this.presentLoading("GeoJSON wird geladen")
      const reader = new FileReader()
      reader.onerror = e => {
        console.log('Lesefehler: '+JSON.stringify(e, null, 2))
      }
      reader.onload = async e => {
        console.log("onload")
        if (this.mapComponent.geoLayer) {
          this.mapComponent.geoLayer.remove()
          this.mapComponent.controlLayers.removeLayer(this.mapComponent.geoLayer)
        }
        this.storage.geoJSON.geoJSON = JSON.parse(e.target.result)
        await localForage.setItem('geoJSON', JSON.parse(JSON.stringify(this.storage.geoJSON)))
        this.mapComponent.geoLayer = this.mapComponent.layerGeoJSON(this.storage.geoJSON.geoJSON)
        this.mapComponent.geoLayer.addTo(this.map)
        this.mapComponent.controlLayers.addOverlay(this.mapComponent.geoLayer, this.storage.geoJSON.name)
        this.storage.config.overlays.push(this.storage.geoJSON.name)
        loading.then(load => {load.dismiss()})
      }
      reader.readAsText(file)
    },
    addGeoDesktop(event) {
      this.readGeo(event.target.files[0])
    },
    addGeoCordova(filename) {
      this.readGeo(this.fileList[filename])
    },
    deleteGeoJSON () {
      if (this.mapComponent.geoLayer) {
        this.mapComponent.geoLayer.remove()
        this.mapComponent.controlLayers.removeLayer(this.mapComponent.geoLayer)
        this.storage.geoJSON = {}
        localForage.setItem('geoJSON', {})
        this.storage.config.overlays.splice(this.storage.config.overlays.length)
      }
    },
  },
}
</script>

<style>
.leaflet-button {
  border: 2px solid rgba(0,0,0,0.3);
  box-shadow: none;
  background: white;
  margin-top: 10px;
  display: inline-block;
  padding: 4px;
  height: 1.1em;
  width: 1.1em;
  border-radius: 4px;
}
</style>