import localForage from 'localforage'
import axios from 'axios'
import { CONFIG } from '../helpers/config.js'

axios.interceptors.request.use(
  async config => {
    const user = await localForage.getItem('user')
    config.headers['authorization'] = "Basic "+ window.localStorage.getItem('token')
    config.headers['x-api-key'] = process.env.VUE_APP_API_KEY
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
response => {
  return response
},
error => {
  console.log(error.response)
  if (!error.response) {
    CONFIG.headerAlert("Keine Internetverbindung", "Bitte später versuchen", [{text:'OK'}])
  }
  else {
    const { status } = error.response
    let errortext
    if (error.response.data.errors[0].description) {
      errortext = error.response.data.errors[0].description
    }
    else
      errortext = error.response.data.errors[0].title
    CONFIG.headerAlert(status ? 'Fehler '+ status : 'Fehler', decodeURIComponent(encodeURIComponent(errortext)), [{text:'OK'}])
  }
  return Promise.reject(error)
})
