<template>
  <ion-item lines="none">
    <ion-label style="font-size:.8rem;margin-bottom:0;margin-left:0">
      {{text+(Object.keys(obj.fotos).length>0?' '+index+' von '+Object.keys(obj.fotos).length:'')}}
    </ion-label>
  </ion-item>
  <swiper-container :slides-per-view="2" @slidechange="slideDidChange" style="padding-bottom:.5rem">
    <swiper-slide v-if="work">
      <ion-card>
        <img :src="'assets/'+(text=='ART-AUFNAHMEN'?'Artbeobachtung.png':'Aufnahme.png')" />
        <ion-button v-if="isCordova" @click="setFoto('CAMERA')" expand="block" fill="outline" color="primary">AUFNEHMEN
          <ion-icon slot="start" :icon="cameraOutline"/>
        </ion-button>
        <ion-button @click="setFoto('F'+obj.id)" expand="block" fill="outline" color="primary">AUSWÄHLEN
          <ion-icon slot="start" :icon="imagesOutline">
          </ion-icon>
            <input type="file" style="display:none" :id="'F'+obj.id" 
              name="file" @change="addFoto($event)"/>
        </ion-button>
      </ion-card>
    </swiper-slide>
    <swiper-slide v-for="(foto, key) in obj.fotos" :key="key">
      <ion-card>
        <img :src="fotos[key]"/>
        <ion-button v-if="work" expand="block" @click="deleteFoto(key)" fill="outline" color="danger">LÖSCHEN
          <ion-icon slot="start" :icon="closeOutline"/>
        </ion-button>
      </ion-card>
    </swiper-slide>
  </swiper-container>
</template>

<script>
  import { register } from 'swiper/element/bundle'
  import { CONFIG } from '../helpers/config.js'
  import Image from '../helpers/image.js'
  import localForage from 'localforage'
  import storeMixin from '../mixins/store.js'
  import utilsMixin from '../mixins/utils.js'
  import {
    IonCard,
    IonButton,
    IonLabel,
    IonItem,
    IonIcon
  } from '@ionic/vue'
  import { 
    cameraOutline, 
    imagesOutline,
    closeOutline
  } from 'ionicons/icons'
  export default {
    components: {
      IonCard,
      IonButton,
      IonLabel,
      IonItem,
      IonIcon
    },
  mixins: [storeMixin, utilsMixin],
  inject: ['storage'],
  props: {
    obj: Object,
    text: String,
    work: Boolean
  },
  data () {
    return {
      cameraOutline,
      imagesOutline,
      closeOutline,
      isCordova: window.cordova,
      index: 1,
      fotos: {}
    }
  },
  async created() {
    register()
    for (const key of Object.keys(this.obj.fotos)) {
      this.fotos[key] = await localForage.getItem('img'+key)
    }
  },
  methods: {
    slideDidChange(event) {
      this.index = event.activeIndex + 1
    },
    setFoto(kind) {
      let options
      console.log(this.obj.id)
      if (window.cordova) {
        if (kind!="CAMERA")
          options = {
            sourceType: window.Camera.PictureSourceType.SAVEDPHOTOALBUM,
            saveToPhotoAlbum: false}
        else
          options = {}
        Image.getImage((fileEntry) => {
          console.log(fileEntry.toURL())
          fileEntry.file(file => {this.addFile(file)})
        }, options)
      }
      else
        document.getElementById(kind).click()
    },
    addFile(file) {
      let id = CONFIG.UUID()
      const reader = new FileReader()
      reader.onload = e => {
        CONFIG.resize(e.target.result, file.type, 200, 200)
          .then((result) => {
            this.obj.fotos[id] = result
          })
        CONFIG.resize(e.target.result, file.type, 2000, 2000)
          .then((result) => {
            localForage.setItem('img'+id, result)
            this.fotos[id] = result
            this.index += 1
          })
      }
      reader.readAsDataURL(file)
    },
    addFoto(event) {
      console.log(this.obj.id)
      this.addFile(event.target.files[0])
    },
    deleteFoto(key) {
      CONFIG.headerAlert("Foto "+key+" vom Gerät entfernen?",
        "Originale bleiben in Fotogalerie erhalten",
        [{text:'Abbruch'},{text:'OK',role:'ok'}])
          .then((result) => {
            if (result.role=="ok") {
              delete this.obj.fotos[key]
              delete this.fotos[key]
              localForage.setItem("samples",  JSON.parse(JSON.stringify(this.storage.samples)))
              this.index -= 1
              localForage.removeItem('img'+key)
            }
          })
    },
  }
}
</script>
<style scoped>
ion-card {
  margin: 0;
  margin-inline:.4rem;
}
ion-button {
  margin: .4rem;
}
</style>