<template>
    <span class="vuelist-item">
      <ion-item @click="$router.push({name: 'singleocc', params: {sample: occurrence.sampleId, occ: occurrence.id}})">
        <div class="round-container">
            <img v-if="Object.keys(occurrence.fotos).length>0"
            :src="occurrence.fotos[Object.keys(occurrence.fotos)[0]]"/>
        </div>
        <ion-label>
          <ion-text style="float:right;font-size:.8rem">
            {{new Date(occurrence.date).toLocaleString('de-DE', {timeZone: 'Europe/Berlin'})}}
          </ion-text><br />
          <ion-text>
            {{occurrence.speciesName}}
          </ion-text><br />
        </ion-label>
      </ion-item>
    </span>
</template>
<script>
import storeMixin from '../mixins/store.js'
import utilsMixin from '../mixins/utils.js'
import { 
  IonItem, 
  IonLabel,
  IonText
} from '@ionic/vue'
import { 
  trash,
} from 'ionicons/icons'
export default {
  components: {
    IonItem, 
    IonLabel,
    IonText,
  },
  inject: ['storage'],
  mixins: [storeMixin, utilsMixin],
  name: 'taxonItem',
  props: {
    occurrence: Object,
    sample: Object,
  },
  data () {
    return {
      trash,
    }
  },
}
</script>
<style scoped>
.vuelist-item {
  transition: all 1s;
}
.round-container {
  box-shadow: 3px 3px 10px 2px rgba(0, 0, 0, 0.15);
  width: 2.5rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 50%;
  background-color: lightgray;
  float: left;
  margin-bottom: .5em;
  margin-right: .5em;
}
.round-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
</style>