import Dexie from 'dexie'
import utilsMixin from './mixins/utils.js'
import App from './App.vue'
import { createApp, h, reactive } from 'vue'
import router from './router'
import localForage from 'localforage'
import 'localforage-getitems'
import './plugins/axios'
import "typeface-roboto"
import 'material-icons/iconfont/material-icons.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

import './theme/variables.css'
import { defineComponent } from 'vue';
import './registerServiceWorker'

let config = {mapView: {center: [49.41049,6.91166], zoom: 9}, 
  overlays: [], baselayer: 'Reisekarte', read: false}
let keys = ['samples','config', 'user', 'attributes', 'geoJSON']
if (!localStorage.getItem('surveyIndicia'))
  localStorage.setItem('surveyIndicia', 84)
let dbVersion = 1
if (localStorage.getItem('dbVersionIndicia') != dbVersion) {
  window.indexedDB.deleteDatabase('Indicia App '+localStorage.getItem('surveyIndicia'))
  window.indexedDB.deleteDatabase('Indicia Dexie '+localStorage.getItem('surveyIndicia'))
}
localStorage.setItem('dbVersionIndicia', dbVersion)
localForage.config({name: 'Indicia App '+ localStorage.getItem('surveyIndicia')})
localForage.getItems(keys).then(values => {
  if (values.attributes == null) {
    values.attributes = [{
      groupName: "", attributes: [
        {
          attrName: "date",
          attributeType: "S",
          controlType: "date",
          text: "Datum",
          type: "D",
          values: [],
          validationRules: ""
      },
      {
        attrName: "location_name",
        attributeType: "S",
        controlType: "",
        text: "Fundortname",
        type: "T",
        values: [],
        validationRules: "required"
      },
      {
        attrName: "recorder_names",
        attributeType: "S",
        controlType: "",
        text: "Kartierer",
        type: "T",
        values: [],
        validationRules: "required"
      },
  ]
    }]
    localForage.setItem('attributes', values.attributes)
  }
  if (values.samples == null)
    values.samples = {}
  if (values.geoJSON == null)
    values.geoJSON = {}
  if (values.config == null) {
    values.config = config
  }
  else {
    values.config = Object.assign(config, values.config)
  }
  let lfGlobal = localForage.createInstance({name: "Indicia Global"})
  lfGlobal.getItem("globalSettings").then( globalSettings => {
    if (globalSettings == null) {
      globalSettings = {}
      globalSettings.surveys = {}
      globalSettings.gridType = 8
      globalSettings.gridChange = false
      globalSettings.shareType = "csv"
      globalSettings.taxonNames = {
        "Wissenschaftliche Namen durchsuchen": true,
        "Deutsche Namen durchsuchen": true,
        "Nur akzeptierte Namen durchsuchen": true,
        "Wissenschaftliche Namen anzeigen": true,
        "Deutsche Namen anzeigen": true,
        "Autorennamen anzeigen": false,
        "Nur akzeptierte Namen anzeigen": true,
        "In akzeptierte Namen umwandeln": false,
        "Kommentar zur Bestimmung": true,
        "Hinweis schon gefundenes Taxon": true,
        "Unbekanntes Taxon": true,
      }
    }
    else if (!globalSettings.gridChange)
      globalSettings = Object.assign(globalSettings, {gridChange: false})
    lfGlobal.setItem('globalSettings', globalSettings)
    lfGlobal.getItem("user").then( user => {
      if (user == null) {
        user = {token: null, profile: null}
        lfGlobal.setItem('user', user)
      }
      const app = createApp(defineComponent({
      mixins: [utilsMixin],
      computed: {
        storage: function () {
          return {
            samples: this.samples,
            user: this.user,
            config: this.config,
            attributes: this.attributes,
            geoJSON: this.geoJSON,
            taxaReady: this.taxaReady,
            smpAttrs: "",
            occAttrs: "",
            lfGlobal: lfGlobal,
            globalSettings: this.globalSettings
          }
        }
      },
      provide() { 
        return {
        'storage': reactive(this.storage)
      }
      },
      async created () {
//        document.getElementById('the_html').style.fontSize = "25px"
        const db = new Dexie('Indicia Dexie')
        if (this.storage.attributes.length==1&&window.localStorage.getItem('token')) {
          await this.indiciaAttributes('/mobile/sample_attributes/sample_occurrence_attributes_for_website.xml', this.storage.attributes)
        }
        this.initAttrs()
        db.version(1).stores({
          taxa: "id, *genus, *species, *taxonListId, *commonName1, *commonName2"
        })
        db.open()
          .then(() => {
            window.db = db
        })
        navigator.geolocation.watchPosition(()=>{}, ()=>{}, {enableHighAccuracy: true, maximumAge: 0})
      },
      data () {
        return {
          samples: values.samples,
          user: user,
          config: values.config,
          attributes: values.attributes,
          geoJSON: values.geoJSON,
          taxaReady: true,
          smpAttrs: "",
          occAttrs: "",
          globalSettings: globalSettings
        }
      },
      watch: { 
        attributes: { 
          handler() { 
            localForage.setItem('attributes', JSON.parse(JSON.stringify(this.attributes)))
          }, deep: true 
        },
        samples: { 
          handler(value) {
            localForage.setItem('samples', JSON.parse(JSON.stringify(this.samples)))
          }, deep: true 
        },
        config: { 
          handler() { 
            localForage.setItem('config', JSON.parse(JSON.stringify(this.config)))
          }, deep: true 
        },
        user: { 
          handler() { 
            lfGlobal.setItem('user', JSON.parse(JSON.stringify(this.user)))
          }, deep: true 
        },  
        globalSettings: { 
          handler() { 
            lfGlobal.setItem('globalSettings', JSON.parse(JSON.stringify(this.globalSettings)))
          }, deep: true 
        },  
      },
      render: () => h(App)
    }))
    app
      .use(router)
      .use(IonicVue, {
        hardwareBackButton: false,
        swipeBackEnabled: false
      })
    router.isReady().then(() => {
      app.mount('#app')
      })
    })
  })
})
